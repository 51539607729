export const BOOKING = {
  BOOKING_HOTEL: {
    BOOKING_HOTEL: 'booking/hotel',
    SEARCH_HOTEL: 'booking/hotel/search',
    FEATURE_HOTEL: 'booking/hotel/featured-hotels',
    HOT_DEAL: 'booking/hotel/hot-deals',
    MAP: 'booking/hotel/map',
    PAYMENT_HOTEL: 'booking/hotel/payment',
  },
  BOOKING_FLIGHT: 'booking/flight',
};

export const MY_BOOKING = {
  MY_BOOKING: 'my-booking/hotel',
  FLIGHT: 'my-booking/flight',
  CAR: 'my-booking',
  TOUR: 'my-bookings',
};

export const OTA = {
  OTA: 'ota',
  HOTEL_OTA: {
    HOTEL_OTA: 'ota/hotel-ota',
    BECOME_AGENCY: 'ota/become-an-agency',
    HOTEL_AGENCY: 'ota/become-an-agency/hotel-agency',
    ACCOMMODATION_CATEGORY: 'accommodation-category',
    PROPERTY_INFO: 'ota/become-an-agency/hotel-agency/property-info',
    GENERAL_INFO: 'general-info',
    PROPERTY_AMENITY: 'property-amenities',
    PROPERTY_PHOTO: 'property-photos',
    PROPERTY_POLICY: 'property-policies',
    PAYMENT_INFO: 'payment-info',
    ROOM: 'room-info/rooms',
    ADD_ROOM: 'room-info/rooms/add-new',
    EDIT_ROOM: 'room-info/rooms/edit',
    REVIEW_INFO: 'room-info/review-info',
    PREVIEW_PROPERTY: 'preview-property',
    CONTRACT_INFO: 'contract-agreement/contract-info',
    REVIEW_CONTRACT: 'contract-agreement/review-contract',
  },
  HOTEL_MANAGEMENT: {
    HOTEL_MANAGEMENT: 'ota/hotel-ota/hotel-management',
    DASHBOARD: 'dashboard',
    GENERAL_INFO: 'property-management/general-info',
    PROPERTY_AMENITY: 'property-management/property-amenities',
    PROPERTY_PHOTO: 'property-management/property-photos',
    PROPERTY_POLICY: 'property-management/property-policies',
    PAYMENT_INFO: 'property-management/payment-info',
    ROOM_INFO: 'room-info',
    EDIT_ROOM: 'room-info/edit',
    ADD_ROOM: 'room-info/add',
    RATE_PLAN: 'rate-plans/rate-plans',
    AVAILABILITY: 'rate-plans/availability',
    PROMOTION: 'rate-plans/promotion',
    MANAGE_RESERVATIONS: 'manage-reservations',
    RESERVATION_DETAILS: 'reservation-details',
    USER_MANAGEMENT: 'user-management',
  },
};
