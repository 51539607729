import { Component, HostListener, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import { InputTextModule } from 'primeng/inputtext';
import { ButtonModule } from 'primeng/button';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { Router } from '@angular/router';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { FooterService } from '@modules/booking/services/footer/footer.service';
import { TranslateModule } from '@ngx-translate/core';
import { DialogModule } from 'primeng/dialog';
import { DropdownModule } from 'primeng/dropdown';
import { SoctripTranslationService } from 'src/app/services/soctrip-translation/soctrip-translation.service';
import { environment } from '@env/environment';
import { SharedModule } from '@shared/shared.module';
import { BOOKING, MY_BOOKING } from '@shared/constants/routes';
import { UserService } from 'src/app/core/services/user/user.service';

@Component({
  selector: 'app-footer',
  standalone: true,
  imports: [
    CommonModule,
    InputTextModule,
    FormsModule,
    ReactiveFormsModule,
    ButtonModule,
    TranslateModule,
    DialogModule,
    DropdownModule,
    SharedModule,
  ],
  templateUrl: './footer.component.html',
})
export class FooterComponent implements OnInit {
  isShowFooter: Boolean;
  visiblePopupChangeLanguage = false;
  disableSearchButtonApply: boolean = true;
  countries: any[] = [];
  languages: any[] = [];
  currencies: any[] = [];

  selectedCountry: any;
  selectedLanguage: any;
  selectedCurrency: any;
  currentCountry: any;
  currentLanguage: any;
  currentCurrency: any;
  constructor(
    private router: Router,
    private fb: FormBuilder,
    private footerService: FooterService,
  ) {
    this.subscribeForm = this.fb.group({
      email: [
        '',
        [
          Validators.required,
          Validators.pattern(
            '[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\\.[a-zA-Z]{2,4}'
          ),
          this.emailValidator,
        ],
      ],
    });
  }
  ngOnInit(): void {
    this.initData();
    this.footerService.getIsShowFooter().subscribe((data) => {
      this.isShowFooter = data;
    });
  }

  emailValidator(control: any) {
    if (control.value && /[^a-zA-Z0-9@.]/.test(control.value)) {
      return { invalidEmail: true };
    }
    return null;
  }

  subscribeForm: FormGroup;
  submittedSubscribe = false;
  currentPage = 'booking';
  isHoveredFacebook = false;
  isHoveredYoutube = false;
  emailRegister: string;
  isRegisterSuccess = false;
  aboutUs: any = {
    contact: {
      phoneNumber: '1800 6868',
      email: 'contact@soctrip.com',
      //email: 'contact@tmtravel.com',
      address: '12 Hoang Van Thu st, Ward 8, Tan Binh dist, HCMC',
      language: 'VIE',
    },
    services: [
      {
        name: 'HEADER.MENU.BOOKING_MENU.FLIGHTS',
        link: 'flight',
      },
      {
        name: 'HEADER.MENU.BOOKING_MENU.HOTELS',
        link: 'hotel',
      },
      {
        name: 'HEADER.MENU.BOOKING_MENU.CAR_RENTALS',
        link: 'car-rental',
      },
      {
        name: 'HEADER.MENU.BOOKING_MENU.TOURS',
        link: 'tour',
      },
    ],
    info: [
      {
        name: 'FOOTER.TERM_CONDITION',
        link: 'terms-and-conditions',
      },
      {
        name: 'FOOTER.INFORMATION_SECURITY',
        link: 'information-security',
      },
      {
        name: 'FOOTER.OPERATIONAL',
        link: 'operational-regulations',
      },
      {
        name: 'FOOTER.FAQ',
        link: 'faqs',
      },
    ],
  };

  // Validate when click on it Subcribe
  onInputChange(value: string) {
    this.isRegisterSuccess = false;
    this.submittedSubscribe = false;
  }

  onSubmitSubscribe() {
    this.submittedSubscribe = true;
    if (this.subscribeForm.valid) {
      this.isRegisterSuccess = true;
    }
  }

  // Click button Facebook && Youtube
  handleClickPlatform(typePlatform: string) {
    if (typePlatform === 'facebook') {
      alert('This feature is developing.');
    } else {
      alert('This feature is developing.');
    }
  }

  onMouseEnterIcon(typePlatform: string) {
    if (typePlatform === 'facebook') {
      this.isHoveredFacebook = true;
    } else {
      this.isHoveredYoutube = true;
    }
  }

  onMouseLeaveIcon(typePlatform: string) {
    if (typePlatform === 'facebook') {
      this.isHoveredFacebook = false;
    } else {
      this.isHoveredYoutube = false;
    }
  }

  // Set a threshold value (in pixels) to determine when the "Go to Top" button should be shown
  private readonly SCROLL_THRESHOLD = 200;
  showGoToTopButton = false;

  @HostListener('window:scroll')
  onWindowScroll() {
    // Show/hide the "Go to Top" button based on the scroll position
    this.showGoToTopButton = window.scrollY >= this.SCROLL_THRESHOLD;
  }

  handleLinkBooking(booking_type_item: any) {
    switch (booking_type_item.link) {
      case 'tour':
        return environment.TRAVEL_ASSIST_URL + '/' + MY_BOOKING.TOUR;
      case 'car-rental':
        return environment.CAR_URL + '/' + MY_BOOKING.CAR;
      case 'hotel':
        return environment.BOOKING_URL + '/' + MY_BOOKING.MY_BOOKING;
      default: // Handle 'hotel' case
        if (this.router.url === `/${BOOKING.BOOKING_FLIGHT}`) {
          window.scrollTo({
            top: 0,
            behavior: 'smooth',
          });
          return '#';
        } else return `/my-booking/${booking_type_item.link}`;
    }
  }

  handleLinkAbout(typeAbout: any) {
    this.currentPage = 'booking';
    this.router.navigate(['/' + this.currentPage + '/' + typeAbout.link]);
  }

  handleLinkSubscription(typeSubscription: string) {
    this.currentPage = 'booking';
    switch (typeSubscription) {
      case 'Google Play':
        window.open(
          'https://play.google.com/store/apps/details?id=com.soctrip.social&hl=en_US'
        );
        break;
      case 'App Store':
        window.open(
          'https://apps.apple.com/vn/app/soctrip/id6469956803'
        );
        break;
      case 'Registered':
        alert('This feature is developing.');
        break;
      case 'Announced':
        alert('This feature is developing.');
        break;
    }
  }

  initData() {
    this.countries = [
      { name: 'United States', code: 'US' },
      { name: 'Viet Nam', code: 'VN' },
    ];
    this.languages = [
      {
        name: 'English (US)',
        code: 'en',
        country_code: 'US',
        icon_name: 'assets/images/common-icon/us.svg',
      },
      {
        name: 'Tiếng Việt',
        code: 'vi',
        country_code: 'VN',
        icon_name: 'assets/images/common-icon/vn.svg',
      },
    ];
    this.currencies = [
      {
        name: 'US Dollar',
        symbol: '$',
        code: 'USD',
        locale: 'en-US',
        value: 'USD $',
      },
      {
        name: 'Vietnamese Dong',
        symbol: '₫',
        code: 'VND',
        locale: 'vi-VN',
        value: 'VND ₫',
      },
    ];
  }
}
