import { Component, Input } from '@angular/core';
import { FooterService } from '@modules/booking/services/footer/footer.service';
@Component({
  selector: 'app-price-format',
  templateUrl: './price-format.component.html',
  styleUrls: ['./price-format.component.scss'],
})
export class PriceFormatComponent {
  @Input() price: number;
  @Input() styleClass: string;
  currentCurrency: any;

  constructor(private footerService: FooterService) {}
  ngOnInit(): void {
    this.footerService.getCurrency().subscribe((data) => {
      this.currentCurrency = data?.code;
    });
  }

  formatPrice(price: number): string {
    const formattedPrice = new Intl.NumberFormat('en-US', {
      style: 'decimal',
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    }).format(price);
    return `${formattedPrice} ${this.currentCurrency}`;
  }
}
