import { Injectable } from '@angular/core';
import { LangChangeEvent, TranslateService } from '@ngx-translate/core';
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class SoctripTranslationService {
  private languageSubject = new BehaviorSubject<any>({});
  language$: Observable<string> = this.languageSubject.asObservable();

  constructor(private translateService: TranslateService) {
    this.translateService.onLangChange.subscribe((event: LangChangeEvent) => {
      this.languageSubject.next(event.lang);
    });
  }

  setLanguage(lang: string) {
    this.translateService.use(lang.toLowerCase());
  }

  getTranslation(key: string): string {
    return this.translateService.instant(key);
  }
}
