import { Component } from '@angular/core';
import { CommonModule } from '@angular/common';
import { Router, RouterModule, RouterOutlet } from '@angular/router';
import { TranslateModule } from '@ngx-translate/core';
import { SharedModule } from 'primeng/api';
import { OverlayModule } from 'primeng/overlay';
import { HeaderComponent } from './header/header.component';
import { HeaderService } from '@modules/booking/services/header/header.service';
import { FooterService } from '@modules/booking/services/footer/footer.service';
import { Subscription } from 'rxjs';
import { environment } from '@env/environment';
interface IModuleList {
  id: number;
  module_name: string;
  short_name: string;
  icon: string;
  link: string;
}
@Component({
  selector: 'app-category-module',
  standalone: true,
  imports: [
    CommonModule,
    RouterOutlet,
    SharedModule,
    OverlayModule,
    TranslateModule,
    HeaderComponent,
    RouterModule,
    RouterModule,
  ],
  templateUrl: './category-module.component.html',
  styleUrls: ['./category-module.component.scss'],
})
export class CategoryModuleComponent {
  moduleList: IModuleList[];
  checkAgency: Subscription;
  constructor(
    private headerService: HeaderService,
    private footerService: FooterService,
    private router: Router
  ) {
    const sessionId = localStorage.getItem(environment.SESSION_ID);
    if (sessionId) {
      localStorage.removeItem(environment.SESSION_ID);
    }
  }

  ngOnInit() {
    this.headerService.setPropertyHeader({
      isShow: false,
      isFixed: false,
      isChangeColor: false,
      isWhite: false,
    });
    this.footerService.setIsShowFooter(false);
    this.initData();
  }

  onClickModule(module: IModuleList) {
    switch (module.short_name) {
      case 'social':
        window.location.href = environment.SOCIAL_APP_URL;
        break;
      case 'shop':
        window.location.href = environment.ECOMMERCE_URL;
        break;
      case 'restaurant':
        window.location.href = environment.ECOMMERCE_FOOD_URL;
        break;
      case 'travel-tour':
        window.location.href = environment.TRAVEL_ASSIST_URL;
        break;
      case 'car':
        window.location.href = environment.CAR_URL;
        break;
      default: // Handle 'hotel' case
        window.location.href = environment.BOOKING_URL;
        break;
    }
  }

  initData() {
    this.moduleList = [
      {
        id: 1,
        module_name: 'CATEGORY_MODULE.SOCIAL',
        short_name: 'social',
        icon: 'assets/icons/ota/module/social.svg',
        link: '',
      },
      {
        id: 2,
        module_name: 'CATEGORY_MODULE.SHOP',
        short_name: 'shop',
        icon: 'assets/icons/ota/module/shop.svg',
        link: '',
      },
      {
        id: 3,
        module_name: 'CATEGORY_MODULE.RESTAURANT',
        short_name: 'restaurant',
        icon: 'assets/icons/ota/module/restaurant.svg',
        link: '',
      },
      {
        id: 4,
        module_name: 'CATEGORY_MODULE.HOTEL',
        short_name: 'hotel',
        icon: 'assets/icons/ota/module/hotel.svg',
        // link: 'become-an-agency'
        link: 'hotel-ota',
      },
      {
        id: 5,
        module_name: 'CATEGORY_MODULE.TRAVEL_TOUR',
        short_name: 'travel-tour',
        icon: 'assets/icons/ota/module/travel-tour.svg',
        link: '',
      },

      {
        id: 6,
        module_name: 'CATEGORY_MODULE.CAR',
        short_name: 'car',
        icon: 'assets/icons/ota/module/car.svg',
        link: '',
      },
    ];
  }
}
