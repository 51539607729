import { Injectable } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  CanActivate,
  RouterStateSnapshot,
  createUrlTreeFromSnapshot,
  UrlTree,
} from '@angular/router';
import { Observable, map } from 'rxjs';
import { PermissionService } from '../../services/permission/permission.service';
import { environment } from '@env/environment';
@Injectable({
  providedIn: 'root',
})
export class PermissionGuard implements CanActivate {
  constructor(private permissionService: PermissionService) {}
  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ):
    | Observable<boolean | UrlTree>
    | Promise<boolean | UrlTree>
    | boolean
    | UrlTree {
    return this.permissionService.userRoles.pipe(
      map((roles) => {
        if (
          this.permissionService.checkPermission(route.data['roles'], roles)
        ) {
          return true;
        } else {
          return createUrlTreeFromSnapshot(route, ['/']);
        }
      })
    );
  }
}
