import { DatePipe } from '@angular/common';
import { Component } from '@angular/core';
import { AbstractControl, FormControl, FormGroup } from '@angular/forms';
import { Router } from '@angular/router';
import {
    ReservationCommonControllerService,
    ReservationCodeRequestDTO
} from '@booking/angular-booking-reservation-service';
import { environment } from '@env/environment';
import { firstValueFrom, Subscription } from 'rxjs';

interface BookingType {
    id: string;
    booking_type_name: string;
    short_name: string;
    text_color: string;
    icon: string;
    background_color?: string;
}
@Component({
    selector: 'app-booking-menu',
    templateUrl: './booking-menu.component.html',
    styleUrls: ['./booking-menu.component.scss'],
})
export class BookingMenuComponent {
    searchBookingForm: FormGroup;
    isSearchBooking: boolean = false;
    isShowPinCode: boolean = false;
    moduleSearch: string;
    formattedDataResponse: any;
    isShowNoResult: boolean = false;
    isSearching: boolean = false;
    private subscriptions: Subscription = new Subscription();

    bookingType: string = 'flight';
    bookingTypeList: BookingType[] = [];
    visible: boolean = false;

    // nav modal
    // modalBookingType = 'flight';
    // modalBookingTypeList: BookingType[] = [];

    baseImageId = environment.STORAGE_SERVICE_API_BASE_PATH_ID_IMAGE;

    constructor(
        private router: Router,
        private reservationCommonControllerService: ReservationCommonControllerService
    ) {
        let MENU_ITEM = ['flight', 'hotel', 'car-rental', 'tours'];
        const commonElements = this.router.url
            .split('/')
            .filter((element_url) => MENU_ITEM.includes(element_url));

        this.bookingType = commonElements ? commonElements[0] : 'flight';

        this.bookingTypeList = [
            {
                id: '1',
                booking_type_name: 'HEADER.MENU.BOOKING_MENU.FLIGHTS',
                short_name: 'flight',
                text_color: 'text-palette-blue-600',
                icon: 'sctr-icon-plane',
            },
            {
                id: '2',
                booking_type_name: 'HEADER.MENU.BOOKING_MENU.HOTELS',
                short_name: 'hotel',
                text_color: 'text-palette-rose-600',
                icon: 'sctr-icon-building-01',
            },
            {
                id: '3',
                booking_type_name: 'HEADER.MENU.BOOKING_MENU.CAR_RENTALS',
                short_name: 'car-rental',
                text_color: 'text-palette-orange-600',
                icon: 'sctr-icon-car-01',
            },
            {
                id: '4',
                booking_type_name: 'HEADER.MENU.BOOKING_MENU.TOURS',
                short_name: 'tours',
                text_color: 'text-palette-purple-600',
                icon: 'sctr-icon-flag-05',
            },
        ];

        // this.modalBookingTypeList = [
        //     {
        //         id: '1',
        //         booking_type_name: 'HEADER.MENU.BOOKING_MENU.FLIGHTS',
        //         short_name: 'flight',
        //         icon: 'sctr-icon-plane',
        //         text_color: 'text-palette-blue-600',
        //         background_color: 'bg-palette-blue-50',
        //     },
        //     {
        //         id: '2',
        //         booking_type_name: 'HEADER.MENU.BOOKING_MENU.HOTELS',
        //         short_name: 'hotel',
        //         icon: 'sctr-icon-building-07',
        //         text_color: 'text-palette-rose-600',
        //         background_color: 'bg-palette-rose-50',
        //     },
        //     {
        //         id: '3',
        //         booking_type_name: 'HEADER.MENU.BOOKING_MENU.CAR_RENTALS',
        //         short_name: 'car-rental',
        //         icon: 'sctr-icon-car-01',
        //         text_color: 'text-palette-orange-600',
        //         background_color: 'bg-palette-orange-50',
        //     },
        //     {
        //         id: '4',
        //         booking_type_name: 'HEADER.MENU.BOOKING_MENU.TOURS',
        //         short_name: 'tours',
        //         icon: 'sctr-icon-flag-05',
        //         text_color: 'text-palette-purple-600',
        //         background_color: 'bg-palette-purple-50',
        //     },
        // ];
    }
    ngOnInit() {
        this.searchBookingForm = new FormGroup({
            searchBookingId: new FormControl(''),
            pinCode: new FormControl('')
        });

        const searchBookingIdSub = this.searchBookingForm.get('searchBookingId')?.valueChanges.subscribe(value => {
            if (this.isShowNoResult) this.isShowNoResult = false;
        });
        const pinCodeSub = this.searchBookingForm.get('pinCode')?.valueChanges.subscribe(value => {
            if (this.isShowNoResult) this.isShowNoResult = false;
        });

        this.subscriptions.add(searchBookingIdSub);
        this.subscriptions.add(pinCodeSub);
    }

    ngOnDestroy() {
        this.subscriptions.unsubscribe();
    }

    handleClickNav(menuItemName: BookingType) {
        switch (menuItemName.short_name) {
            case 'tours':
                return environment.TRAVEL_ASSIST_URL;
            case 'car-rental':
                return environment.CAR_URL;
            case 'hotel':
                return environment.BOOKING_URL;
            default: // Handle 'hotel' case
                return `/booking/${menuItemName.short_name}`;
        }
    }

    showDialog() {
        this.visible = true;
    }

    onCloseModal() {
        this.isSearchBooking = false;
        this.isShowNoResult = false;
        this.isShowPinCode = false;
        this.isSearching = false;
        this.formattedDataResponse = null;
        this.searchBookingForm.setValue({
            searchBookingId: '',
            pinCode: ''
        });
    }

    togglePassword() {
        this.isShowPinCode = !this.isShowPinCode;
    }

    get f(): { [key: string]: AbstractControl } {
        return this.searchBookingForm.controls;
    }

    handleSearch() {
        if (this.searchBookingForm.get('searchBookingId')?.value?.trim() && this.searchBookingForm.get('pinCode')?.value?.trim()) {
            this.searchBooking();
        }
    }

    async searchBooking() {
        const reservationCodeRequestDTO: ReservationCodeRequestDTO = {
            reservation_code: this.searchBookingForm.get('searchBookingId')?.value?.toUpperCase(),
            pin_code: this.searchBookingForm.get('pinCode')?.value,
        }
        try {
            this.isSearching = true;
            const response = await firstValueFrom(
                this.reservationCommonControllerService.searchReservationDetail(
                    reservationCodeRequestDTO,
                    'body',
                    true
                )
            );
            if (response.success) {
                this.isShowNoResult = false;
                this.moduleSearch = response.data.module;
                if (this.moduleSearch === 'HOTEL') {
                    this.handleDataHotelResponse(response.data.detail);
                } else if (this.moduleSearch === 'FLIGHT') {
                    this.handleDataFlightResponse(response.data.detail);
                }
                this.isSearching = false;
            } else {
                this.isShowNoResult = true;
                this.formattedDataResponse = null;
                this.isSearching = false;
            }
        } catch (err) {
            console.error(err);
            this.formattedDataResponse = null;
            this.isSearching = false;
        }
        this.isSearchBooking = true;
    }

    handleDataHotelResponse(responseDataSearch: any) {
        const { property, reservation_code, checkin_date, checkout_date, qr_link } = responseDataSearch;

        let formattedDataResponse: any = {
            property_image_overview_id: property.property_image_overview_id,
            property_image_overview_url: property.property_image_overview_url,
            property_name: property.property_name,
            reservation_code,
            checkin_date,
            checkout_date,
            qr_link
        };
        this.formattedDataResponse = formattedDataResponse;
    }

    handleDataFlightResponse(responseDataSearch: any) {
        const { booking_info, flight_info, qr_link } = responseDataSearch;
        const { reservation_code } = booking_info;
        const { itineraries, one_way } = flight_info;

        const formatSegmentData = (segments: any[]) => {
            const firstSegment = segments[0];
            const lastSegment = segments[segments.length - 1];
            return {
                startCityName: firstSegment.departure.city_name,
                endCityName: lastSegment.arrival.city_name,
                isOneDay: firstSegment.date_departure_at === lastSegment.date_departure_at,
                startTime: firstSegment.time_departure_at?.slice(0, 5),
                endTime: lastSegment.time_arrival_at?.slice(0, 5),
                startDate: firstSegment.date_departure_at,
                endDate: lastSegment.date_departure_at
            };
        }

        let formattedDataResponse: any = {
            one_way,
            reservation_code,
            qr_link,
            depart: formatSegmentData(itineraries[0].segments)
        };

        if (!one_way && itineraries.length > 1) {
            formattedDataResponse.return = formatSegmentData(itineraries[1].segments);
        }
        this.formattedDataResponse = formattedDataResponse;
    }

    replaceWithDefaultImage(event: any): void {
        event.target.src = 'assets/images/temporary/default-hotel.svg';
    }
}
