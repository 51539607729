<app-header></app-header>
<div class="flex justify-center mt-[164px] font-inter">
    <div>
        <div class="w-full flex justify-center">
            <p class="text-2xl text-palette-gray-900 font-semibold">{{ 'CATEGORY_MODULE.SELECT_MODULE' | translate}}</p>
        </div>
        <div class="grid grid-cols-6 max-[1200px]:grid-cols-4 max-[768px]:grid-cols-2 max-[370px]:grid-cols-1 !gap-6 mt-[48px]">
            <div *ngFor="let module of moduleList"
                class="flex justify-center items-center w-[180px] max-w-[180px] h-[196px] border bg-white shadow-sm cursor-pointer
                border-palette-gray-200 rounded-lg text-palette-gray-700 hover:!bg-palette-blue-600 hover:text-white "
                (click)="onClickModule(module)">
                <div>
                    <div class="h-[72px] w-[72px] min-w-[72px] max-w-[72px] bg-white rounded-full flex justify-center items-center">
                        <img src="{{module.icon}}" alt="" class="!w-14 h-14" />
                    </div>
                    <div class="text-base font-medium flex justify-center">
                        {{ module.module_name | translate }}
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>