import { NgModule } from '@angular/core';
import { CountdownFormatPipe } from './pipes/coundown-format.pipe';
import { TruncateStringPipe } from './pipes/truncate-string.pipe';
import { RelativeTimePipe } from './pipes/relative-time.pipe';
import { CustomDatePipe } from './pipes/custom-date.pipe';
import { NumberFormatPipe } from './pipes/number-format.pipe';
import { SortByInteractionsPipe } from './pipes/sort-by-interactions.pipe';
import { SortPipe } from './pipes/sort.pipe';
import { SlugifyPipe } from './pipes/slugify.pipe';
import { BytesConversionPipe } from './pipes/bytes-conversion.pipe';
import { FilterPipe } from './pipes/filter.pipe';
import { DatetimeFormatterModule, DateFormatPipe } from '@soctrip-common/datetime-formatter'
import { DatePipe } from '@angular/common';

import { CommonModule, TitleCasePipe } from '@angular/common';
import { TagModule } from 'primeng/tag';
import { TooltipModule } from 'primeng/tooltip';
import { DialogModule } from 'primeng/dialog';
import { ModalComponent } from './components/modal/modal.component';
import { ToastComponent } from './components/toast/toast.component';
import { Toast, ToastModule } from 'primeng/toast';
import { ButtonModule } from 'primeng/button';
import { InputTextModule } from 'primeng/inputtext';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { OverlayPanelModule } from 'primeng/overlaypanel';
import { BsDatepickerModule } from 'ngx-bootstrap/datepicker';
import { ModalModule } from 'ngx-bootstrap/modal';
import { DropdownModule } from 'primeng/dropdown';
import { GalleriaModule } from 'primeng/galleria';
import { OverlayModule } from 'primeng/overlay';
import { DialogService, DynamicDialogRef } from 'primeng/dynamicdialog';
import { TabMenuModule } from 'primeng/tabmenu';
import { TabViewModule } from 'primeng/tabview';
import { ProgressSpinnerModule } from 'primeng/progressspinner';
import { InputNumberModule } from 'primeng/inputnumber';
import { SkeletonModule } from 'primeng/skeleton';
import { TranslateModule } from '@ngx-translate/core';

import { PaginationCustomComponent } from './components/pagination-custom/pagination-custom.component';
import { TagComponent } from './components/tag/tag.component';
import { ShowImageModalComponent } from './components/show-image-modal/show-image-modal.component';
import { TabMenuComponent } from './components/tab-menu/tab-menu.component';
import { CustomizePaginationComponent } from './components/customize-pagination/customize-pagination.component';
import { TimePickerComponent } from './components/time-picker/time-picker.component';
import { PopupRecommendSignIn } from './components/popup-recommend-sign-in/popup-recommend-sign-in.component';
import { SignInRequestDialogComponent } from './components/sign-in-request-dialog/sign-in-request-dialog.component';
import { PopupCtaComponent } from './components/popup-cta/popup-cta.component';
import { PriceFormatComponent } from './components/price-format/price-format.component';
import { CommonIconText } from './components/common-icon-text/common-icon-text.component';
import { TooltipModuleBooking } from './components/tooltip/tooltip.module';
import { StarComponent } from '@shared/components/star/star.component';
import { CheckboxModule } from 'primeng/checkbox';
import { RadioButtonModule } from 'primeng/radiobutton';
import { PopupQrCodeComponent } from './components/popup-qr-code/popup-qr-code.component';
import { LoadingComponent } from './components/loading/loading.component';
import { InputPhoneNumberComponent } from './components/input-phone-number/input-phone-number.component';
import { ConfirmDialogModule } from 'primeng/confirmdialog';
import { ConfirmationService } from 'primeng/api';
import { MeesageErrorFormModule } from './components/meesage-error-form/meesage-error-form.module';
import { DividerModule } from 'primeng/divider';
import { SliderModule } from 'primeng/slider';
import { ButtonComponent } from './components/button/button.component';
import { CalendarComponent } from './components/calendar/calendar.component';
import { TableEmptyComponent } from './components/table-empty/table-empty.component';
import { SentenceCasePipe } from '../core/pipes/sentence-case.pipe';
import { CalendarModule } from 'primeng/calendar';
import { PaginatorModule } from 'primeng/paginator';
import { BookingMenuComponent } from './components/booking-menu/booking-menu.component';

import { CurrencyConversionPipe } from '@soctrip-common/app-security';
import { BannersComponent } from './components/banners/banners.component';
import { SafeURLPipe } from './pipes/safe-url.pipe';
const sharedPipes = [
  CountdownFormatPipe,
  TruncateStringPipe,
  RelativeTimePipe,
  CustomDatePipe,
  RelativeTimePipe,
  NumberFormatPipe,
  SortByInteractionsPipe,
  SortPipe,
  SlugifyPipe,
  BytesConversionPipe,
  FilterPipe,
  SentenceCasePipe,
  SafeURLPipe
];
const shareProviders = [
  Toast,
  DialogService,
  DynamicDialogRef,
  ConfirmationService,
  DatePipe,
  DateFormatPipe,
];
const shareModules = [
  DialogModule,
  ButtonModule,
  InputTextModule,
  FormsModule,
  ReactiveFormsModule,
  OverlayPanelModule,
  BsDatepickerModule,
  ModalModule,
  DropdownModule,
  ToastModule,
  TagModule,
  TooltipModule,
  GalleriaModule,
  OverlayModule,
  TabViewModule,
  TabMenuModule,
  TranslateModule,
  ProgressSpinnerModule,
  TooltipModuleBooking,
  InputNumberModule,
  CheckboxModule,
  RadioButtonModule,
  SkeletonModule,
  ConfirmDialogModule,
  MeesageErrorFormModule,
  SliderModule,
  DividerModule,
  CalendarModule,
  PaginatorModule,
  CurrencyConversionPipe,
  DatetimeFormatterModule,
];
const shareComponents = [
  ModalComponent,
  ToastComponent,
  PaginationCustomComponent,
  TagComponent,
  ShowImageModalComponent,
  TabMenuComponent,
  CustomizePaginationComponent,
  TimePickerComponent,
  PopupRecommendSignIn,
  SignInRequestDialogComponent,
  PopupCtaComponent,
  PriceFormatComponent,
  CommonIconText,
  StarComponent,
  PopupQrCodeComponent,
  LoadingComponent,
  InputPhoneNumberComponent,
  ButtonComponent,
  TableEmptyComponent,
  CalendarComponent,
  BookingMenuComponent,
  BannersComponent
];
@NgModule({
  declarations: [sharedPipes, shareComponents],
  imports: [CommonModule, shareModules],
  exports: [CommonModule, sharedPipes, shareComponents, shareModules],
  providers: [shareProviders, TitleCasePipe],
})
export class SharedModule {}
