import { Component, EventEmitter, Input, Output, OnInit } from '@angular/core';
import { ImagesService } from '@modules/my-booking/services/image/media.service';
import { FileControllerService } from '@soctrip/angular-storage-service';
import { environment } from '@env/environment';
import { CopyService } from '@modules/booking/services/copy/copy.service';
import { ToastType } from '@shared/enum/toast-type';
import { MessageService } from 'primeng/api';
import { SoctripTranslationService } from 'src/app/services/soctrip-translation/soctrip-translation.service';

@Component({
  selector: 'app-popup-qr-code',
  templateUrl: './popup-qr-code.component.html',
  styleUrls: ['./popup-qr-code.component.scss'],
  providers: [MessageService],
})
export class PopupQrCodeComponent implements OnInit {
  @Input() qrCode: any;
  @Input() qrLink: any;
  @Input() bookingId: any;
  @Output() closePopupEvent = new EventEmitter<boolean>();

  public isShowPopup: boolean = true;
  public urlImg: any;

  constructor(
    private imagesService: ImagesService,
    private fileControllerService: FileControllerService,
    private copyService: CopyService,
    private messageService: MessageService,
    private soctripTranslationService: SoctripTranslationService
  ) {}

  ngOnInit(): void {
    this.getQRCode();
  }

  getQRCode() {
    this.fileControllerService.getFile(this.qrCode).subscribe({
      next: (url) => {
        if (url) {
          this.urlImg = `${environment.baseURL}/storage/files/web/${this.qrCode}.webp`;
        }
      },
    });
  }

  copyLink() {
    const successful = this.copyService.copyToClipboard(this.qrLink);
    if (successful) {
      this.messageService.add({
        severity: ToastType.SUCCESS,
        detail: this.soctripTranslationService.getTranslation(
          'QRCODE.COPY_SUCCESSFULLY'
        ),
      });
    } else {
      this.messageService.add({
        severity: ToastType.ERROR,
        detail:
          this.soctripTranslationService.getTranslation('QRCODE.COPY_FAILED'),
      });
    }
  }

  stopPropagationEvent(event: Event): void {
    event.stopPropagation();
  }

  public downloadImage(url: string, filename: string) {
    this.imagesService.downloadImage(url, filename);
  }

  public closePopup() {
    this.isShowPopup = false;
    this.closePopupEvent.emit(false);
  }
}
