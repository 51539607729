<div class="flex justify-center items-center h-[100vh] w-[100vw] !bg-palette-base-white">
    <div class="flex flex-col items-center">
        <img class="!-mt-[12rem]" src="assets/images/background/under-development.svg" alt="">
        <div class="font-inter flex flex-col items-center !gap-4">
            <p class="text-3xl !leading-[38px] font-semibold !text-palette-gray-900">
                {{'UNDER_DEV.FEATURE_UNDER_DEVELOPMENT' | translate}}
                ...</p>
            <p class="text-sm font-normal text-palette-gray-500">
                {{'UNDER_DEV.DESCRIPTION' | translate}}
            </p>
            <a [href]="socialAppUrl" class="button !mt-10 whitespace-nowrap">
                {{'UNDER_DEV.BACK_TO_HOME' | translate}}
                <i class="sctr-icon-arrow-right icon"></i>
            </a>
        </div>
    </div>
</div>