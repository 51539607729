<div
  id="menu-bar"
  [ngClass]="handleMenuBar()"
  class="fixed top-0 z-20 h-[68px] w-full whitespace-nowrap"
>
  <lib-header
    [environment]="environment"
    [userInfo]="userInfo"
    [currentPage]="'booking'"
    [currentTheme]="currentTheme"
    [(megaVisible)]="megaVisible"
    [myBookingUrl]="myBookingUrl"
    [visibleSearch]="false"
    [visibleShopingCart]="visibleShoppingCart"
    [loginReturnUrl]="loginReturnUrl"
    [currentLanguage] = "currentLang"
  >
  </lib-header>
</div>
