import { CommonModule } from '@angular/common';
import {
  Component,
  ElementRef,
  QueryList,
  Renderer2,
  ViewChildren,
} from '@angular/core';
import { Router, RouterOutlet } from '@angular/router';
import { environment } from '@env/environment';
import { UserInfo } from '@modules/users/models/user';
import { TranslateModule } from '@ngx-translate/core';
import { TIME_OUT_REQUEST } from '@shared/constant';
import { SharedModule } from '@shared/shared.module';
import { OverlayModule } from 'primeng/overlay';
import { Subject, takeUntil, timeout } from 'rxjs';
import { UserService } from 'src/app/core/services/user/user.service';
import { RouterModule, Routes } from '@angular/router';
@Component({
  selector: 'app-header',
  standalone: true,
  imports: [
    CommonModule,
    RouterOutlet,
    SharedModule,
    OverlayModule,
    TranslateModule,
    RouterModule,
  ],
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
})
export class HeaderComponent {
  @ViewChildren('fullName') fullNameElements: QueryList<ElementRef>;
  baseUrl = environment.BASE_PATH;
  overlayMoreVisible = false;
  overlayUserVisible = false;
  visiblePopupLogout = false;
  isLogin: boolean | null;
  userInfo: UserInfo;
  onDestroy$: Subject<void> = new Subject();
  public fullNameArray: any;

  constructor(
    private router: Router,
    private userService: UserService,
    private renderer: Renderer2
  ) {}

  ngOnInit() {
    this.getUserInfo();

    this.userService
      .getLoginStatus()
      .pipe(takeUntil(this.onDestroy$))
      .subscribe((data) => {
        if (data !== null) {
          this.isLogin = data;
        }
      });
  }

  ngOnDestroy(): void {
    this.onDestroy$.next();
    this.onDestroy$.complete();
  }

  getUserInfo() {
    this.userService
      .getUserInfor()
      .pipe(takeUntil(this.onDestroy$))
      .subscribe((user) => {
        if (user) this.userInfo = user;
      });
  }

  public mouseOverHotelName() {
    this.fullNameArray = this.fullNameElements.toArray();
    this.handleShowTooltip(this.fullNameArray, 'full-name');
  }

  public handleShowTooltip(els: ElementRef[], className: string): void {
    for (var val of els) {
      const isTruncated =
        val.nativeElement.scrollWidth > val.nativeElement.clientWidth;
      if (isTruncated) {
        this.renderer.addClass(val.nativeElement, className);
      } else {
        this.renderer.removeClass(val.nativeElement, className);
      }
    }
  }

  onSignIn() {
    window.location.href = environment.SOCIAL_APP_URL + '/login?r='+ encodeURIComponent(window.location.href);
  }

  onClickMyBookingItem() {
    this.router.navigate(['my-booking']);
  }
  onClickSavedListItem() {
    window.location.href = environment.TRAVEL_ASSIST_URL + '/saved-list';
  }
  handleClickMenuMore() {
    this.overlayMoreVisible = !this.overlayMoreVisible;
  }

  onClickMyServices() {
    this.router.navigate(['ota']);
  }

  toggleCustomSetting() {
    this.overlayUserVisible = !this.overlayUserVisible;
  }
  displayPopupLogout(): void {
    // this.visiblePopupDeactivateAccount = false;
    this.visiblePopupLogout = true;
    // this.overlayVisible = false;
  }
  closePopupLogout(): void {
    this.visiblePopupLogout = false;
  }
  handleSignOut(): void {
    this.visiblePopupLogout = false;
    window.location.href = environment.SOCIAL_APP_URL + '/login';
    localStorage.removeItem(environment.AUTH_STATUS);
    localStorage.removeItem(environment.TOKEN_KEY);
    localStorage.removeItem(environment.USER_PROFILE);
    localStorage.removeItem(environment.ACCESS_TOKEN_EXPIRY_KEY);
    localStorage.removeItem(environment.REFRESH_TOKEN_EXPIRY_KEY);
    localStorage.removeItem(environment.REFRESH_TOKEN_KEY);
    localStorage.removeItem(environment.USER_ROLE_KEY);
    this.userService.setUserProfile(null);
    this.userService.setUserInfo(null);
    this.userService.setLoginStatus(false);
  }
  handleClickLogo(): void {
    this.router.navigate(['/ota/become-an-agency']);
  }
}
