<div class="relative">
  <div class="relative">
    <input pInputText class="md:!w-[400px] h-9 min-h-0 truncate" placeholder="Select date" type="text"
      (click)="filterOP.toggle($event)" readonly [value]="dateString" />
    <i [class]="
        SoctripIcons.CALENDAR +
        ' absolute text-palette-gray-500 right-3 top-1/2 -translate-y-1/2 text-lg cursor-pointer'
      " (click)="filterOP.toggle($event)"></i>
  </div>
  <p-overlayPanel #filterOP>
    <div class="h-full flex gap-4 p-4" (click)="$event.stopPropagation()">
      <div class="h-full grid gap-2 mt-0.5">
        <label *ngFor="let item of DF" class="py-1 font-medium cursor-pointer text-sm" [ngClass]="
            DFActive === item.value
              ? 'text-primary-600'
              : 'hover:text-primary-500'
          " (click)="filterDate(item)">
          {{ "primeng." + item.value | translate | sentenceCase }}
        </label>
      </div>
      <div class="h-full flex flex-col gap-4">
        <p-calendar ngClass="min-w-[15rem] border-none" [(ngModel)]="rangeDates"
          (ngModelChange)="getDateFilterArray($event)" selectionMode="range" [numberOfMonths]="2" [showIcon]="true"
          [inline]="true"></p-calendar>
        <div class="flex gap-2 justify-between">
          <span class="flex gap-2 items-center">
            <p-calendar [(ngModel)]="dateFrom" (change)="setDateFilter($event)" ngClass="w-[10rem]"
              [showOnFocus]="false" dateFormat="dd/mm/yy"></p-calendar>
            <span>-</span>
            <p-calendar [(ngModel)]="dateTo" (change)="setDateFilter($event)" ngClass="w-[10rem]" [showOnFocus]="false"
              dateFormat="dd/mm/yy"></p-calendar>
          </span>
          <span class="flex gap-2">
            <button
              class="!w-[88px] !h-11 border !px-5 font-inter !text-sm font-semibold rounded-lg !py-3 bg-white text-palette-gray-900"
              (click)="filterOP.toggle($event)">
              {{ "COMMON.CANCEL" | translate }}
            </button>
            <button
              class="!w-[88px] !h-11 border !px-5 font-inter !text-sm font-semibold rounded-lg !py-3 bg-palette-blue-600 text-white"
              [disabled]="
                (!rangeDates[0] && rangeDates[1]) ||
                (rangeDates[0] && !rangeDates[1])
              " (click)="
                rangeDatesChange.emit(rangeDates);
                onApply.emit();
                filterOP.toggle($event)
              ">
              {{ "COMMON.APPLY" | translate }}
            </button>
          </span>
        </div>
      </div>
    </div>
  </p-overlayPanel>
</div>