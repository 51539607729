<div class="h-[420px] mt-5 bg-white py-[30px] footer-section hidden sm:block" *ngIf="isShowFooter">
    <div class="lg:mx-[12.5%] md:mx-[8%] mx-[2%] flex justify-center">
        <div class="w-full pt-5 pb-5 flex gap-[10px] justify-between max-w-[1440px]">
            <div class="w-2/12 ml-2">
                <div class="mt-1 min-h-[30px] min-w-[30px] lg:min-w-[124px] !flex justify-start items-center cursor-pointer">
                    <img class="min-w-[34px] max-w-[34px] lg:hidden" 
                        src="assets/images/logo/blue-logo-soctrip.svg" alt="">
                    <img class="min-h-[34px] max-h-[34px] hidden lg:block"
                        src="assets/images/logo/blue-soctrip-logo.svg" alt="">
                    <!-- <img class="min-w-[34px] max-w-[34px] lg:hidden" 
                        src="assets/images/logo/blue-logo.svg" alt="">
                    <img class="min-h-[34px] max-h-[34px] hidden lg:block"
                        src="assets/images/logo/blue-logo-text.svg" alt=""> -->
                </div>
            </div>
            <div class="w-3/12 space-y-1">
                <p
                    class="text-palette-gray-900 text-base font-semibold overflow-hidden whitespace-nowrap text-ellipsis mb-3">
                    {{'FOOTER.CONTACT' | translate}}</p>
                <common-icon-text class="flex space-x-1" 
                    [icon]="'sctr-icon-phone-call-01'"
                    [iconStyle]="'text-palette-orange-500 text-lg mt-0.5 mr-1 flex items-center'"
                    [text]="aboutUs.contact.phoneNumber"
                    [textStyle]="'text-detail'"></common-icon-text>               
                <common-icon-text class="flex space-x-1 mt-3" 
                    [icon]="'sctr-icon-mail-01'"
                    [iconStyle]="'text-palette-orange-500 text-lg mt-0.5 mr-1 flex items-center'"
                    [text]="aboutUs.contact.email"
                    [textStyle]="'text-detail overflow-hidden whitespace-nowrap text-ellipsis'"></common-icon-text>
                <common-icon-text class="flex space-x-1 mt-3" 
                    [icon]="'sctr-icon-marker-pin-01'"
                    [iconStyle]="'text-palette-orange-500 text-lg mt-0.5 mr-1 flex items-start'"
                    [text]=" aboutUs.contact.address"
                    [textStyle]="'text-detail line-clamp-4'"></common-icon-text>

                <div class="flex space-x-2 items-center mt-3 mr-1">
                    <div class="cursor-pointer" (click)="handleClickPlatform('facebook')">
                        <img class="w-[20px] h-[20px]"
                            src="{{isHoveredFacebook ? 'assets/images/home/icon/active-facebook-icon.svg' : 'assets/images/home/icon/gray-facebook-icon.svg'}}"
                            alt="" (mouseenter)="onMouseEnterIcon('facebook')"
                            (mouseleave)="onMouseLeaveIcon('facebook')">
                    </div>
                    <div class="cursor-pointer" (click)="handleClickPlatform('youtube')">
                        <img class="w-[20px] h-[20px]"
                            src="{{isHoveredYoutube ? 'assets/images/home/icon/active-youtube-icon.svg' : 'assets/images/home/icon/gray-youtube-icon.svg'}}"
                            alt="" (mouseenter)="onMouseEnterIcon('youtube')"
                            (mouseleave)="onMouseLeaveIcon('youtube')">
                    </div>
                    <!-- <div class="cursor-pointer flex items-center space-x-2" (click)="handleClickChangeLanguage()">
                        <img class="h-[16px] w-[16px]" src="assets/images/home/icon/gray-earth-icon.svg" alt="">
                        <p
                        class="text-detail overflow-hidden whitespace-nowrap text-ellipsis cursor-pointer w-[25px] hover:text-blue-500">
                        {{selectedLanguage.country_code}}</p>
                    </div> -->
                 
                </div>
            </div>
            <div class="w-2/12 space-y-1 max-[1500px]:pl-0">
                <p class="text-palette-gray-900 text-base font-semibold mb-3">{{'HEADER.MENU.BOOKING' | translate}}</p>
                <a *ngFor="let item of aboutUs.services; let i = index" class="flex" [href]="handleLinkBooking(item)">
                    <div class="text-detail hover:text-blue-500 cursor-pointer {{i == 0 ? '' : 'mt-[10px]'}}"
                    >{{item.name | translate}} </div>
                </a>
            </div>
            <div class="w-2/12">
                <p class="text-palette-gray-900 text-base font-semibold mb-3">{{'FOOTER.ABOUT' | translate}}</p>
                <div *ngFor="let item of aboutUs.info; let i = index" class="flex">
                    <p class="text-detail hover:text-blue-500 cursor-pointer {{i == 0 ? '' : 'mt-[15px]'}}"
                        (click)="handleLinkAbout(item)">{{item.name |translate}} </p>
                </div>
            </div>
            <div class="w-3/12 xl:pl-6 max-[1200px]:pl-0">
                <p class="text-palette-gray-900 text-base font-semibold mb-3">{{'FOOTER.SUBSCRIPTION' | translate}}</p>
                <p class="text-detail">{{'FOOTER.SUBSCRIPTION_DESCRIPTION' | translate}}</p>
                <form [formGroup]="subscribeForm" (ngSubmit)="onSubmitSubscribe()">
                    <div class="flex mt-3 mb-2 max-[1400px]:flex-col">
                        <input type="text" formControlName="email" pInputText class="h-[44px] max-[1400px]:w-[100%] mr-2 w-[65%] font-inter font-normal 
                            text-base text-palette-gray-500 border
                            {{submittedSubscribe && (subscribeForm.get('email')?.hasError('pattern') || subscribeForm.get('email')?.hasError('invalidEmail')) && subscribeForm.get('email')?.touched ? 'border-red-500' : 'border-[#ced4da]'}}
                            {{submittedSubscribe && subscribeForm.get('email')?.hasError('required') && subscribeForm.get('email')?.touched ? 'border-red-500' : 'border-[#ced4da]'}}"
                            #textInput [(ngModel)]="emailRegister" placeholder="{{'FOOTER.PLACEHOLDER.YOUR_EMAIL' | translate}}"  (input)="onInputChange(textInput.value)" 
                        />
                        <p-button type="submit"
                            class="h-[44px] register-btn max-[1400px]:mt-2 text-white rounded-lg whitespace-nowrap !font-inter !font-semibold w-[35%] max-[1400px]:w-[100%]"
                            label="{{'COMMON.SUBSCRIBE' | translate}}"></p-button>
                    </div>
                </form>
                <div>
                    <div class="text-sm py-2 text-red-600 italic"
                        *ngIf="submittedSubscribe && subscribeForm.get('email')?.hasError('required') && subscribeForm.get('email')?.touched">
                        {{'FOOTER.VALIDATION.EMAIL_REQUIRED' | translate}}
                    </div>
                    <div class="text-sm py-2 text-red-600 italic"
                        *ngIf="submittedSubscribe && (subscribeForm.get('email')?.hasError('pattern') || subscribeForm.get('email')?.hasError('invalidEmail')) && subscribeForm.get('email')?.touched">
                        {{'FOOTER.VALIDATION.EMAIL_INVALID' | translate}}
                    </div>
                    <div class="text-sm py-2 text-green-600 italic" *ngIf="submittedSubscribe && isRegisterSuccess">
                        {{'FOOTER.VALIDATION.THANK_YOU' | translate}}
                    </div>
                </div>
                <div>
                    <div class="flex space-x-4 pt-3">
                        <div class="cursor-pointer" (click)="handleLinkSubscription('Google Play')">
                            <img src="assets/images/home/affiliate-brand/google-play.png" alt="">
                        </div>
                        <div class="cursor-pointer" (click)="handleLinkSubscription('App Store')">
                            <img src="assets/images/home/affiliate-brand/app-store.png" alt="">
                        </div>
                    </div>
                    <div class="flex space-x-4 pt-3">
                        <div class="cursor-pointer" (click)="handleLinkSubscription('Registered')">
                            <img src="assets/images/home/affiliate-brand/gov-vn-registered.png" alt="">
                        </div>
                        <div class="cursor-pointer" (click)="handleLinkSubscription('Announced')">
                            <img src="assets/images/home/affiliate-brand/gov-vn.png" alt="">
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>