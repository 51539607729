<div class="relative booking-menu w-full flex flex-col min-[480px]:flex-row gap-2">
    <div class="service flex h-10 w-full items-center justify-between gap-[9px] p-1 rounded-lg bg-white lg:w-fit">
        <ng-container *ngFor="let item of bookingTypeList">
            <a [href]="handleClickNav(item)"
                class="h-[32px] !px-3 py-[6px] rounded-md hover:bg-palette-gray-100 transition-all"
                [ngClass]="bookingType === item.short_name ? 'bg-palette-blue-100 px-1 text-palette-blue-600' : '' ">
                <common-icon-text 
                    class="flex justify-center items-center h-5"
                    [icon]="item.icon"
                    [iconStyle]="item.text_color + ' text-base flex justify-center items-end'"
                    [text]="item.booking_type_name"
                    [textStyle]="'ml-1 lg:ml-2 whitespace-nowrap text-[9px] sm:text-sm font-semibold sm:block'">
                </common-icon-text>
            </a>
        </ng-container>
    </div>
    <button
        class="rounded-lg min-w-[172px] px-4 py-2 items-center justify-center text-xs md:text-sm font-semibold leading-5
        text-palette-blue-600 hover:text-palette-blue-700 bg-white hover:!bg-palette-blue-100 transition-colors hidden sm:flex"
        (click)="showDialog()">
        {{'BOOKING_HOME.SEARCH_BOOKING' | translate}}
    </button>
</div>

<div class="search-booking-id-modal-section">
    <p-dialog header="{{'MY_BOOKING.SEARCH_YOUR_BOOKING' | translate}}" [(visible)]="visible" [modal]="true"
        [style]="{ width: '650px', height: 'auto' }" [draggable]="false" [resizable]="false" (onHide)="onCloseModal()">
        <div class="flex flex-col">
            <!-- ORDER_ID AND PIN_CODE -->
            <form [formGroup]="searchBookingForm" (keydown.enter)="handleSearch()">
                <div class="w-[100%] h-[56px] mb-3 flex gap-3 tel-input !px-5 !py-[14px]">
                    <input
                        type="text"
                        class="w-[100%] h-[100%] outline-none font-inter text-lg font-normal text-palette-gray-900 placeholder:normal-case uppercase"
                        formControlName="searchBookingId"
                        placeholder="{{'MY_BOOKING.PLACEHOLDER.ENTER_ORDER_ID' | translate}}"
                        autocomplete="off">
                </div>
        
                <div class="w-[100%] h-[56px] mb-2 flex items-center gap-3 tel-input !px-5 !py-[14px]">
                    <input 
                        #searchInput
                        class="w-[95%] h-[100%] outline-none font-inter text-lg font-normal text-palette-gray-900"
                        formControlName="pinCode"
                        type="{{isShowPinCode ? 'text' : 'password'}}"
                        placeholder="{{'MY_BOOKING.PLACEHOLDER.PIN_CODE' | translate}}"
                        autocomplete="new-password">
                    <i (click)="togglePassword()"
                        class="{{isShowPinCode ? 'sctr-icon-eye' : 'sctr-icon-eye-off'}} w-[24px] h-[24px] text-xl cursor-pointer text-palette-gray-700">
                    </i>
                </div>
            </form>

            <!-- SEARCH RESULT -->
            <div *ngIf="isSearchBooking">
                <!-- NO RESULT MATCH -->
                <div *ngIf="isShowNoResult"
                    class="flex flex-col justify-center items-center mt-3">
                    <div class="w-[48px] h-[48px] rounded-full bg-palette-gray-50 flex justify-center items-center">
                        <div class="w-[36px] h-[36px] rounded-full bg-palette-gray-100 flex justify-center items-center">
                            <i class="sctr-icon-file-x-02 text-xl text-palette-gray-700"></i>
                        </div>
                    </div>
                    <p class="font-inter text-lg font-semibold text-palette-gray-900 mt-2 mb-1">
                        {{'MY_BOOKING.NO_RESULTS_FOUND' | translate}}
                    </p>
                    <p class="font-inter text-base font-normal text-palette-gray-600">
                        {{'MY_BOOKING.NO_RESULTS_FOUND_DESCRIPTION' | translate}}</p>
                    <p class="font-inter text-base font-normal text-palette-gray-600">
                        {{'MY_BOOKING.PLEASE_TRY_ANOTHER_ID' | translate}}
                    </p>
                </div>
                <!-- HAS RESULT MATCH -->
                <ng-container *ngIf="formattedDataResponse">
                    <!-- RESULT SEARCH: Hotel -->
                    <ng-container *ngIf="moduleSearch === 'HOTEL'">
                        <a href="{{ formattedDataResponse.qr_link }}"
                            class="flex gap-3 mt-3 w-[100%] rounded-md p-2 bg-palette-gray-50 cursor-pointer hover:bg-palette-gray-100 transition-all">
                            <img *ngIf="formattedDataResponse.property_image_overview_id"
                                class="!w-[80px] !h-[80px] max-w-[80px] rounded-md object-cover border"
                                src="{{baseImageId}}/{{formattedDataResponse.property_image_overview_id}}.webp"
                                (error)="replaceWithDefaultImage($event)">
                            <!-- <div *ngIf="!formattedDataResponse.property_image_overview_id">
                                123
                            </div> -->
                            <img *ngIf="!formattedDataResponse.property_image_overview_id"
                                class="!w-[80px] !h-[80px] max-w-[80px] rounded-md object-cover border"
                                src="{{formattedDataResponse.property_image_overview_url}}"
                                (error)="replaceWithDefaultImage($event)">
                            <div class="flex flex-col gap-2 w-[100%]">
                                <common-icon-text 
                                    class="flex items-center gap-2"
                                    [icon]="'sctr-icon-building-07'"
                                    [iconStyle]="'text-xl text-palette-gray-700'"
                                    [text]="formattedDataResponse.property_name"
                                    [textStyle]="'text-xl font-semibold text-palette-gray-700'">
                                </common-icon-text>
                                <div class="w-[100%] flex justify-between">
                                    <div class="flex flex-col">
                                        <p class="font-inter text-sm font-normal text-palette-gray-500">
                                            {{'PAYMENT.PAYMENT_RESULT.ORDER_ID' | translate}}
                                        </p>
                                        <p class="font-inter text-base font-medium text-branding-primary-700">
                                            {{formattedDataResponse.reservation_code}}
                                        </p>
                                    </div>
                                    <div class="flex flex-col">
                                        <p class="font-inter text-sm font-normal text-palette-gray-500">
                                            {{'PAYMENT.CHECK_IN' | translate}}
                                        </p>
                                        <p class="font-inter text-base font-medium text-palette-gray-900">
                                            {{formattedDataResponse.checkin_date | dateFormat}}
                                        </p>
                                    </div>
                                    <div class="flex flex-col">
                                        <p class="font-inter text-sm font-normal text-palette-gray-500">
                                            {{'PAYMENT.CHECK_OUT' | translate}}
                                        </p>
                                        <p class="font-inter text-base font-medium text-palette-gray-900">
                                            {{formattedDataResponse.checkout_date | dateFormat}}
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </a>
                    </ng-container>
                    <!-- RESULT SEARCH: Flight -->
                    <ng-container *ngIf="moduleSearch === 'FLIGHT'">
                        <a href="{{ formattedDataResponse.qr_link }}"
                            class="mt-3 font-inter bg-palette-gray-50 cursor-pointer w-[100%] rounded-md p-2 flex !gap-3 hover:bg-palette-gray-100 transition-all">
                            <div class="image-item rounded-lg {{formattedDataResponse.one_way ? 'bg-blue-50' : 'bg-amber-50'}} 
                                flex items-center justify-center flex-col !gap-2 w-[68px]">
                                <div class="{{formattedDataResponse.one_way ? 'border-palette-blue-50 bg-palette-blue-100' : 'border-palette-amber-50 bg-palette-amber-100'}} 
                                    !w-8 !h-8 rounded-full flex justify-center border ">
                                <ng-container *ngIf="formattedDataResponse.one_way; else roundTrip">
                                    <img src="./assets/images/flight-booking-simply/flight-icon-blue.svg" alt="">
                                </ng-container>
                                <ng-template #roundTrip>
                                    <img src="./assets/images/flight-booking-simply/flight-yellow.svg" alt="">
                                </ng-template>
                                </div>
                                <p class="font-semibold not-italic text-[10px] whitespace-nowrap">
                                    {{(formattedDataResponse.one_way ? 'FLIGHT.SEARCH_RESULT.ONE_WAY' : 'FLIGHT.SEARCH_RESULT.ROUND_TRIP_1') | translate}}
                                </p>
                            </div>
                            <div class="xl:flex gap-3 font-inter">
                                <!-- DEPARTURE -->
                                <div class="flex flex-col justify-end" [ngClass]="{'h-full': formattedDataResponse.one_way}">
                                    <a class="text-palette-blue-600 text-sm font-medium not-italic mb-2.5">
                                        {{formattedDataResponse.reservation_code}}
                                    </a>
                                    <p class="text-palette-gray-900 font-medium text-base not-italic">
                                        {{formattedDataResponse.depart.startCityName}} -> {{formattedDataResponse.depart.endCityName}}
                                    </p>
                                    <div class="flex gap-2 items-center">
                                        <span><i class="sctr-icon-calendar text-palette-gray-500"></i></span>
                                        <ng-container *ngIf="formattedDataResponse.depart.isOneDay">
                                            <p class="text-sm font-semibold text-palette-gray-500">
                                                {{ formattedDataResponse.depart.startTime }} - {{ formattedDataResponse.depart.endTime }}
                                            </p>
                                            <i class="sctr-icon-solid-circle text-palette-gray-500 text-[6px]"></i>
                                            <p class="text-sm text-palette-gray-500">
                                                {{ formattedDataResponse.depart.startDate | dateFormat }}
                                            </p>
                                        </ng-container>
                                        <ng-container *ngIf="!formattedDataResponse.depart.isOneDay">
                                            <p class="text-sm text-palette-gray-500 flex !gap-1">
                                                <span class="font-semibold">{{ formattedDataResponse.depart.startTime }}</span> {{ formattedDataResponse.depart.startDate | dateFormat }}
                                            </p>
                                            <i class="sctr-icon-solid-circle text-palette-gray-500 text-[6px]"></i>
                                            <p class="text-sm text-palette-gray-500 flex !gap-1">
                                                <span class="font-semibold">{{ formattedDataResponse.depart.endTime }}</span> {{ formattedDataResponse.depart.endDate | dateFormat }}
                                            </p>
                                        </ng-container>
                                    </div>
                                </div>
                                <!-- RETURN -->
                                <div *ngIf="!formattedDataResponse.one_way" class="flex flex-col justify-end flex-1">
                                    <p class="text-palette-gray-900 font-medium font-inter text-base not-italic">
                                        {{formattedDataResponse.return.startCityName}} -> {{formattedDataResponse.return.endCityName}}
                                    </p>
                                    <div class="flex gap-2 items-center">
                                        <span><i class="sctr-icon-calendar text-palette-gray-500"></i></span>
                                        <ng-container *ngIf="formattedDataResponse.return.isOneDay">
                                            <p class="text-sm font-semibold text-palette-gray-500">
                                                {{ formattedDataResponse.return.startTime }} - {{ formattedDataResponse.return.endTime }}
                                            </p>
                                            <i class="sctr-icon-solid-circle text-palette-gray-500 text-[6px]"></i>
                                            <p class="text-sm text-palette-gray-500">
                                                {{ formattedDataResponse.return.startDate | dateFormat }}
                                            </p>
                                        </ng-container>
                                        <ng-container *ngIf="!formattedDataResponse.return.isOneDay">
                                            <p class="text-sm text-palette-gray-500 flex !gap-1">
                                                <span class="font-semibold">{{ formattedDataResponse.return.startTime }}</span> {{ formattedDataResponse.return.startDate | dateFormat }}
                                            </p>
                                            <i class="sctr-icon-solid-circle text-palette-gray-500 text-[6px]"></i>
                                            <p class="text-sm text-palette-gray-500 flex !gap-1">
                                                <span class="font-semibold">{{ formattedDataResponse.return.endTime }}</span> {{ formattedDataResponse.return.endDate | dateFormat }}
                                            </p>
                                        </ng-container>
                                    </div>
                                </div>
                            </div>
                        </a>
                    </ng-container>
                </ng-container>
            </div>

            <!-- BUTTON SEARCH -->
            <button 
                (click)="handleSearch()"
                [disabled]="!f['searchBookingId'].value?.trim() || !f['pinCode'].value?.trim() || isSearching"
                class="w-[100%] h-[52px] {{!f['searchBookingId'].value.trim() || !f['pinCode'].value.trim() || isSearching ? 'bg-branding-primary-200' : 'bg-branding-primary-600 hover:bg-branding-primary-700'}} 
                rounded-lg px-5 py-3 font-inter text-lg font-semibold text-white flex justify-center items-center mt-5 !mb-7">
                <div class="flex items-center !gap-3 ">
                    <p>{{'COMMON.SEARCH' | translate}}</p>
                    <div *ngIf="isSearching" class="flex items-center justify-center space-x-2 !mt-2">
                        <div class="loader rounded-[50%] border-r-[2px] border-t-[2px] border-white opacity-1 h-[20px] w-[20px] mr-2 mb-2"></div>
                    </div>
                </div>
            </button>
        </div>
    </p-dialog>
</div>