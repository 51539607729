<div class="shadow-header flex justify-between items-center w-full h-[68px] !px-6 py-[14px] font-inter bg-palette-blue-600 border-b border-palette-gray-200 fixed top-0 z-[10000]">
    <div class="flex gap-2">
        <button (click)="handleClickLogo()">
            <!-- <img src="assets/images/logo/white-logo-text.svg" alt="" class="min-w-[125px] max-h-[30px] min-h-[30px]"> -->
            <img src="assets/images/logo/Soctrip-logo.svg" alt="" class="min-w-[125px] max-h-[30px] min-h-[30px]">
        </button>
        <div class="text-2xl font-medium leading-8 text-white">Center</div>
    </div>
    <div class="flex gap-2 items-center">
        <button *ngIf="isLogin" class="px-2 py-1 relative hover:bg-palette-gray-200 rounded" (click)="handleClickMenuMore()">
            <i class="sctr-icon-dots-grid text-2xl text-white"></i>
            <!-- Modal when click More Button -->
            <p-overlay [(visible)]="overlayMoreVisible" class="absolute top-2 right-[220px]"
                [responsive]="{ direction: 'bottom-end', contentStyleClass: 'w-30rem' }">
                <ng-template pTemplate="content">
                    <div class="min-w-[220px] lg:min-h-fit font-inter bg-white mt-2 rounded-lg p-2 flex flex-wrap shadow border border-palette-gray-200">
                    <button (click)="onClickMyBookingItem()"
                        class="flex gap-2 w-full items-center py-2 px-3 rounded-lg hover:shadow-lg hover:bg-palette-blue-light-25">
                        <i class="sctr-icon-building-07 text-xl text-palette-gray-500"></i>
                        <p class="text-sm leading-3 break-words text-palette-gray-700">{{'MY_BOOKING.MY_BOOKING' | translate}}</p>
                    </button>
                    <button (click)="onClickSavedListItem()"
                        class="flex gap-2 w-full items-center py-2 px-3 rounded-lg hover:shadow-lg hover:bg-palette-blue-light-25">
                        <i class="sctr-icon-bookmark text-xl text-palette-gray-500"></i>
                        <p class="text-sm  leading-3 break-words text-palette-gray-700">{{'MY_BOOKING.SAVED_LIST' |
                        translate}}</p>
                    </button>
                    <button (click)="onClickMyServices()"
                    class="flex gap-2 w-full items-center py-2 px-3 rounded-lg hover:shadow-lg hover:bg-palette-blue-light-25">
                    <img class="!w-5 !h-5" src="assets/images/common-icon/soctrip-center.svg" alt="">
                    <p class="text-sm  leading-3 break-words text-palette-gray-700">{{'HEADER.SOCTRIP_CENTER' |
                      translate}}</p>
                  </button>
                    </div>
                </ng-template>
            </p-overlay>
        </button>

        <button class="relative px-2 py-1 hover:bg-palette-gray-200 rounded">
            <i class="sctr-icon-bell-01 text-2xl text-white"></i>
            <div class="flex justify-center items-center overflow-hidden w-[18px] h-[18px] absolute top-[1px] right-[2px] bg-transparent">
                <div class="flex items-center justify-center bg-palette-orange-dark-600 text-white text-[8px] font-bold leading-[10px] rounded-full !w-4 h-4">
                    4
                </div>
            </div>
        </button>

        <div *ngIf="isLogin" class="flex items-center gap-3 cursor-pointer" (click)="toggleCustomSetting()">
            <div class="box-avatar rounded-full flex justify-center items-center relative !w-10 min-w-[40px] h-10 min-h-[40px]">
                <div class="rounded-full flex justify-center items-center h-full w-full ">
                    <img *ngIf="userInfo && userInfo.avatar_thumbnail_url" class="rounded-full w-[34px] min-h-[34px] h-[34px] min-w-[34px]" 
                    src="{{baseUrl}}/storage/files/web/{{userInfo.avatar_thumbnail_url}}.webp">
                </div>
                <!-- Modal when click user avatar -->
                <!-- <p-overlay [(visible)]="overlayUserVisible" [contentStyleClass]="'shadow-2 border-round'"
                    class="absolute top-2 -left-[220px] md:-left-[110px] rounded-lg"
                    [responsive]="{ direction: 'bottom-end', contentStyleClass: 'w-30rem' }">
                        <div class="rounded-lg overflow-hidden bg-white">
                            <div class="flex flex-col gap-3 px-3 py-3 w-[260px] bg-palette-base-white border-b border-palette-gray-200 cursor-auto">
                                <button [routerLink]="['/personal-profile', userInfo.id]" class="flex items-center w-full bg-palette-blue-50 text-palette-blue-700 hover:bg-palette-blue-100  
                                            text-base font-semibold h-[44px] rounded-md justify-center">
                                    <span>{{'HEADER.OPTIONS.VIEW_PROFILE' | translate}}</span>
                                </button>
                            </div>
                            <button class="w-full px-[15px] py-1 hover:bg-palette-gray-200" (click)="displayPopupLogout()">
                                <common-icon-text class="px-[9px] h-[44px] flex items-center gap-2 text-bg-palette-red-600 cursor-pointer font"
                                    [icon]="'sctr-icon-log-out-01'"
                                    [iconStyle]="'text-xl text-palette-red-700'"
                                    [text]="'HEADER.OPTIONS.SIGN_OUT'"
                                    [textStyle]="'text-palette-gray-700 font-medium'">
                                </common-icon-text>
                            </button>
                        </div>
                </p-overlay> -->
            </div>
            <div class="hidden md:flex flex-col relative">
                <div #fullName (mouseover)="mouseOverHotelName()" class="text-sm font-semibold leading-5 max-w-[200px] truncate text-white">{{ userInfo.full_name }}</div>
                <p class="absolute inline-block whitespace-nowrap !px-3 py-2 text-xs font-semibold text-center transition-opacity duration-200 ease-in bg-white rounded-lg tooltip-full-name z-100 text-palette-gray-700">
                    {{ userInfo.full_name }}
                </p>
                <div class="text-xs font-medium leading-4 text-white">Food Star</div>
            </div>
        </div>
        <div *ngIf="!isLogin">
            <div class="box-avatar rounded-full flex justify-center items-center relative !w-10 min-w-[40px] h-10 min-h-[40px]">
                <div class="rounded-full flex justify-center items-center h-full w-full ">
                <img  (click)="onSignIn()" class="rounded-full w-[40px] min-h-[40px] h-[40px] min-w-[40px]" 
            src="https://api-dev-tmtrav.tma-swerp.com/media/libraries/default/UserAvatar.jpg">
            </div>
          </div>
          </div>
    </div>
</div>

<!-- Popup Confirm Sign Out -->
<p-dialog header="{{'HEADER.SIGN_OUT_ACCOUNT' | translate}}" [modal]="true" [draggable]="false" [resizable]="false"
  [(visible)]="visiblePopupLogout" [style]="{ width: '400px' }" [styleClass]="'border-0'">
  <div class="flex justify-between gap-3 px-4 pb-4 pt-2 bg-white rounded-b-lg text-base font-semibold">
    <button class="p-button-text w-[100%] border px-[18px] border-palette-blue-700
         text-palette-gray-700 py-2.5 justify-center cursor-pointer rounded-lg flex hover:bg-palette-gray-100" 
         (click)="closePopupLogout()">{{'COMMON.CANCEL' | translate}}</button>
    <button class="p-button-text w-[100%] border px-[18px] border-palette-blue-700
         text-white bg-palette-blue-700 py-2.5 justify-center cursor-pointer rounded-lg flex hover:bg-palette-blue-800" 
         (click)="handleSignOut()">{{'HEADER.OPTIONS.SIGN_OUT' | translate}}</button>
  </div>
</p-dialog>
