import { Component } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SharedModule } from '@shared/shared.module';
import { FooterService } from '@modules/booking/services/footer/footer.service';
import { HeaderService } from '@modules/booking/services/header/header.service';
import { environment } from '@env/environment';

@Component({
  selector: 'app-under-development-page',
  standalone: true,
  imports: [CommonModule, SharedModule],
  templateUrl: './under-development-page.component.html',
  styleUrls: ['./under-development-page.component.scss'],
})
export class UnderDevelopmentPageComponent {
  socialAppUrl = environment.SOCIAL_APP_URL;

  constructor(
    private headerService: HeaderService,
    private footerService: FooterService
  ) {
    this.headerService.setPropertyHeader({ isShow: false });
    this.footerService.setIsShowFooter(false);
  }
}
