import { Routes } from '@angular/router';
import { TasksService } from '@modules/todos/services/tasks.service';
import { PermissionGuard } from './core/guards/permission/permission.guard';
import { USER_PERMISSION, MANAGER_PERMISSION } from './shared';
import { AuthGuard } from './core/guards/auth/auth.guard';
import { environment } from '@env/environment';
import { BOOKING_ROUTES } from '@modules/booking/booking.routes';
import { CategoryModuleComponent } from '@modules/ota/category-module/category-module.component';
import { UnderDevelopmentPageComponent } from '@shared/pages/under-development-page/under-development-page.component';

export const APP_ROUTES: Routes = [
  {
    path: '',
    redirectTo: 'booking',
    pathMatch: 'full',
  },
  {
    path: 'booking',
    loadChildren: () =>
      import('@modules/booking/booking.routes').then(
        ({ BOOKING_ROUTES }) => BOOKING_ROUTES
      ),
  },
  {
    path: 'common/icon',
    loadComponent: () =>
      import('./modules/common/icon/icon.component').then(
        ({ IconComponent }) => IconComponent
      ),
    title: 'Booking: Icon',
  },
  {
    path: 'my-booking',
    canActivate: [AuthGuard],
    loadChildren: () =>
      import('./modules/my-booking/my-bookings.routes').then(
        ({ MY_BOOKING_ROUTES }) => MY_BOOKING_ROUTES
      ),
  },
  {
    path: 'ota',
    canActivate: [AuthGuard, PermissionGuard],
    data: {
      roles: ['USER'],
    },
    component: CategoryModuleComponent,
  },
  {
    path: 'login',
    loadComponent: () =>
      import('./modules/users/components/sign-in/sign-in.component').then(
        ({ SignInComponent }) => SignInComponent
      ),
    title: environment.PRODUCT_NAME + ': Sign In',
  },
  {
    path: 'sign-up',
    loadComponent: () =>
      import('./modules/users/components/sign-up/sign-up.component').then(
        ({ SignUpComponent }) => SignUpComponent
      ),
    title: environment.PRODUCT_NAME + ': Sign Up',
  },
  {
    path: 'common/version',
    loadComponent: () =>
      import('./modules/common/version/version.component').then(
        ({ VersionComponent }) => VersionComponent
      ),
    title: 'checking version',
  },
  {
    path: 'under-development',
    component: UnderDevelopmentPageComponent,
    title: 'Under Development',
  },
  {
    path: '**',
    loadComponent: () =>
      import('./layout/page-not-found/page-not-found.component').then(
        ({ PageNotFoundComponent }) => PageNotFoundComponent
      ),
    title: 'Page Not Found',
  },
];
